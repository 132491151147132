import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Collage, CollageType } from "../components/collages/Collage";

const FigurativePage = () => {
  return (
    <Layout>
      <SEO title="Figurative" />
      <Collage type={CollageType.Figurative} />
    </Layout>
  );
};

export default FigurativePage;
